import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function LandingPageNavbar(props) {
  const { t } = useTranslation();
  const { scrollY, data = {} } = props;

  const { logo = '', logoClassName = '', logoStyle = {}, name = '' } = data;

  return (
    <nav
      className={`navbar navbar-expand-md navbar-dark navbar-custom fixed-top ${
        scrollY > 50 ? 'top-nav-collapse' : ''
      }`}
    >
      <Link className="navbar-brand logo-image text-decoration-none" to="/">
        <img src={logo} alt="" className={logoClassName} style={logoStyle} />
        {name && <h3 className="d-inline mb-0 ml-2">{name}</h3>}
      </Link>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarsExampleDefault"
        aria-controls="navbarsExampleDefault"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-awesome fas fa-bars"></span>
        <span className="navbar-toggler-awesome fas fa-times"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarsExampleDefault">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link page-scroll text-uppercase" href="#header">
              {t('Home')} <span className="sr-only">(current)</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link page-scroll text-uppercase" href="#features">
              {t('Features')}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link page-scroll text-uppercase" href="#preview">
              {t('Preview')}
            </a>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle page-scroll text-uppercase"
              href="#details"
              id="navbarDropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {t('Details')}
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <a className="dropdown-item" href="terms-conditions.html">
                <span className="item-text text-uppercase">
                  {t('Terms & Conditions')}
                </span>
              </a>
              <div className="dropdown-items-divide-hr"></div>
              <a className="dropdown-item" href="privacy-policy.html">
                <span className="item-text text-uppercase">
                  {t('Privacy Policy')}
                </span>
              </a>
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link page-scroll text-uppercase" href="#contact">
              {t('Contact')}
            </a>
          </li>
        </ul>
        <span className="nav-item social-icons">
          <span className="fa-stack">
            <a href="#your-link">
              <i className="fas fa-circle fa-stack-2x"></i>
              <i className="fab fa-facebook-f fa-stack-1x"></i>
            </a>
          </span>
          <span className="fa-stack">
            <a href="#your-link">
              <i className="fas fa-circle fa-stack-2x"></i>
              <i className="fab fa-twitter fa-stack-1x"></i>
            </a>
          </span>
        </span>
      </div>
    </nav>
  );
}

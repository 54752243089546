import React from 'react';
import { generateId } from './util';

export function InputSwitch(props) {
  const {
    className = '',
    label = '',
    disabled = false,
    value = false,
    onChange,
  } = props;
  const [id] = React.useState(generateId());

  function handleChange(e) {
    if (onChange) {
      onChange(e.target.checked);
    }
  }

  return (
    <div className={`custom-control custom-switch ${className}`}>
      <input
        type="checkbox"
        className="custom-control-input"
        id={id}
        disabled={disabled}
        value={value}
        checked={value}
        onChange={handleChange}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

export const Type = {
  string: 'string',
  number: 'number',
  object: 'object',
  array: 'array',
};

function fromNumber(n) {
  if (typeof n === 'number') return `${n}`;
  return null;
}

function fromObject(o) {
  if (typeof o === 'object' && o !== null) return JSON.stringify(o);
  return null;
}

function fromArray(a) {
  if (Array.isArray(a)) return JSON.stringify(a);
  return null;
}

function toNumber(s) {
  const n = Number(s);
  return isNaN(n) ? null : n;
}

function toObject(s) {
  if (typeof s === 'string' && s.length > 0)
    try {
      const o = JSON.parse(s);
      if (typeof o === 'object') return o;
    } catch (e) {}
  return null;
}

function toArray(s) {
  if (typeof s === 'string' && s.length > 0) {
    try {
      const a = JSON.parse(s);
      if (Array.isArray(a)) return a;
    } catch (e) {}
  }
  return null;
}

export default class Storage {
  static set(k, v) {
    if (typeof k === 'string' && k.length > 0) {
      if (typeof v === 'number') {
        const s = fromNumber(v);
        if (s !== null) localStorage.setItem(k, s);
      } else if (Array.isArray(v)) {
        const s = fromArray(v);
        if (s !== null) localStorage.setItem(k, s);
      } else if (typeof v === 'object') {
        const s = fromObject(v);
        if (s !== null) localStorage.setItem(k, s);
      } else {
        localStorage.setItem(k, v);
      }
    }
  }

  static get(k, t = 'string') {
    if (typeof k === 'string' && k.length > 0) {
      if (t === 'number') {
        const n = toNumber(localStorage.getItem(k));
        if (typeof n === 'number') return n;
      } else if (typeof t === 'string' && t.toLowerCase() === 'array') {
        const a = toArray(localStorage.getItem(k));
        if (Array.isArray(a)) return a;
      } else if (typeof t === 'string' && t.toLowerCase() === 'object') {
        const o = toObject(localStorage.getItem(k));
        if (typeof o === 'object') return o;
      } else {
        return localStorage.getItem(k);
      }
    }
    return null;
  }

  static rem(k) {
    if (typeof k === 'string' && k.length > 0) {
      localStorage.removeItem(k);
    }
  }

  static clear() {
    localStorage.clear();
  }
}

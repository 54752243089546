import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { createLandingPage } from '../api/landing-page.api';

const DESIGNS = ['elegant', 'clean', 'potential', 'cool'];

export default function LandingChooseDesign(props) {
  const { state, notify } = props;
  const { t } = useTranslation();

  const [redirectTo, setRedirectTo] = React.useState();

  async function chooseDesign(design) {
    try {
      const { access_token, email } = state;
      const landingPage = await createLandingPage(access_token, email, design);
      const { slug } = landingPage;
      setRedirectTo(`/landing-page/${slug}`);
    } catch (error) {
      notify(t(`Failed to create new landing page`), 'danger');
    }
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <div className="container-lg py-5">
      <div className="text-center py-5">
        <h1>{t('Choose your design')}</h1>
        <p className="mb-0">{t('Select your landing page design')}</p>
        <small>{t('Any selected design can be changed later')}</small>
      </div>
      <div className="row">
        {DESIGNS.map((design) => (
          <div key={design} className="col-sm-4 col-lg-3 mb-3">
            <button
              className="btn-unstyled"
              onClick={(e) => chooseDesign(design)}
            >
              <div className="rounded-lg shadow-sm shadow-hover">
                <img
                  src={`/img/${design}.png`}
                  alt=""
                  className="w-100"
                  style={{
                    height: 250,
                    objectFit: 'cover',
                    objectPosition: '50% 0',
                    borderTopLeftRadius: '.3rem',
                    borderTopRightRadius: '.3rem',
                  }}
                />
                <h5 className="text-center py-3">{design}</h5>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

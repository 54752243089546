import React from 'react';
import LandingPageHeader from './LandingPageHeader';
//import Testimonial from './Testimonial';
//import Feature from './Feature';
//import Video from './Video';
//import Screenshot from './Screenshot';
//import Download from './Download';
//import Statistics from './Statistics';
//import Contact from './Contact';

export default function LandingPageMain(props) {
  const { data } = props;
  return (
    <main role="main">
      <LandingPageHeader data={data} />
      {/*<Testimonial data={data} />
      <Feature data={data} />
      <Video data={data} />
      <Screenshot data={data} />
      <Download data={data} />
      <Statistics data={data} />
      <Contact data={data} />*/}
    </main>
  );
}

import React from 'react';

export default function LandingPageStyle(props) {
  const { design } = props;

  switch (design) {
    case 'elegant':
      return <link rel="stylesheet" href={`/design/${design}/style.css`} />;
    default:
      return null;
  }
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { fromMd } from '../util';

const CONTENT = `My beloved wife,

__This is a secret love letter 💌 to you__

Firstly, I am sorry 🙏🏻 I could not complete this App 📱. I could not complete it just in time. However, I promise you 🙏🏻 I will finish it. For you and our beloved children.

On the bright side ☀️, I would like to share with you something good 👍. This app has been just started last Friday, May 1st. Built from scratch. So far, in term of functionality it has matched the version I have been building up for months before. So, in term of the flexibilty and the convenience of the code, I have much comfortable working with this new version 👨🏻‍💻. Hence, I have feeling I could complete it sooner rather than later 🤞

And one more thing...

"HAPPY BIRTHDAY 🥳, SAYANG 🧕🏻"

May god bless you 🙏🏻 and our family  👩‍👦‍👦👨‍👧‍👧 with good health and abundance of sustenance 🤲

I will always love you 💖... more than you know... although sometimes I could not show it ... I am in love with you 😘

Love,

__Your secret admirer__ 💘

May 4th, 2020

`;

const STYLE = `@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');
.Secret {
  font-family: 'Dancing Script', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}`;

const BUTTON_STYLE = { width: 45, height: 45, fontSize: 'x-large' };

export default function Secret(props) {
  const { t } = useTranslation();

  const [fontSize, setFontSize] = React.useState(13);

  return (
    <div className="Secret container-lg py-5 animated fadeInRight">
      <style>{STYLE}</style>
      <h1>
        {t('Secret')}{' '}
        <span role="img" aria-label={t('love letter')}>
          💌
        </span>
      </h1>
      <div className="text-right">
        <button
          className="btn btn-light rounded-circle shadow-sm m-1"
          style={BUTTON_STYLE}
          onClick={(e) => setFontSize(fontSize - 10)}
        >
          -
        </button>
        <button
          className="btn btn-light rounded-circle shadow-sm m-1 font-weight-bold"
          style={BUTTON_STYLE}
          onClick={(e) => setFontSize(fontSize + 10)}
        >
          +
        </button>
      </div>
      <div
        className="p-3 rounded bg-light shadow-sm animated slower fadeIn delay-1s"
        dangerouslySetInnerHTML={fromMd(CONTENT)}
        style={{ fontSize }}
      />
    </div>
  );
}

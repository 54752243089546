import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LandingPageCopyright from './LandingPageCopyright';

export default function LandingPageFooter(props) {
  const { t } = useTranslation();
  const { data = {} } = props;
  const { about = {} } = data;
  const { developer, description } = about;
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-col">
                <h4>
                  {t('About')} {developer}
                </h4>
                <p>{description}</p>
              </div>

              <div className="row">
                <div className="col-6 text-center">
                  <Link to="/privacy">{t('Privacy Policy')}</Link>
                </div>
                <div className="col-6 text-center">
                  <Link to="/terms">{t('Terms & Conditions')}</Link>
                </div>
              </div>
            </div>

            <div className="col-md-6 text-center">
              <div className="footer-col last">
                <h4>{t('Social Media')}</h4>
                <span className="fa-stack">
                  <a href="#your-link">
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i className="fab fa-facebook-f fa-stack-1x"></i>
                  </a>
                </span>
                <span className="fa-stack">
                  <a href="#your-link">
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i className="fab fa-twitter fa-stack-1x"></i>
                  </a>
                </span>
                <span className="fa-stack">
                  <a href="#your-link">
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i className="fab fa-google-plus-g fa-stack-1x"></i>
                  </a>
                </span>
                <span className="fa-stack">
                  <a href="#your-link">
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i className="fab fa-instagram fa-stack-1x"></i>
                  </a>
                </span>
                <span className="fa-stack">
                  <a href="#your-link">
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i className="fab fa-linkedin-in fa-stack-1x"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <LandingPageCopyright />
    </React.Fragment>
  );
}

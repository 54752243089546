import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

// Click-To-Edit component
export default function CTE(props) {
  const { t } = useTranslation();
  const { value: originalValue = '', onChange } = props;

  const [edit, setEdit] = React.useState(false);
  const [value, setValue] = React.useState(originalValue);
  const [showAlert, setShowAlert] = React.useState(false);

  React.useEffect(() => {
    setValue(originalValue);
  }, [originalValue]);

  function cancel(e) {
    setValue(originalValue);
    setShowAlert(false);
    setEdit(false);
  }

  React.useEffect(() => {
    function onEsc(e) {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        e.preventDefault();

        // cancel
        setValue(originalValue);
        setShowAlert(false);
        setEdit(false);
      }
    }
    window.addEventListener('keydown', onEsc);
    return () => window.removeEventListener('keydown', onEsc);
  }, [originalValue]);

  function handleSubmit(e) {
    e.preventDefault();
    onChange(value);
    setShowAlert(false);
    setEdit(false);
  }

  function handleBlur(e) {
    if (value === originalValue) {
      cancel();
    } else {
      setShowAlert(true);
    }
  }

  if (edit) {
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit}>
          <input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="shadow-inset w-100"
            style={{
              textAlign: 'inherit',
              color: 'inherit',
              fontWeight: 'inherit',
              background: 'rgba(255,255,255,.25)',
            }}
            onBlur={handleBlur}
            autoFocus
          />
          <div
            className="position-fixed w-100"
            style={{ bottom: 0, left: 0, zIndex: 98 }}
          >
            <div className="d-flex justify-content-between p-3">
              <button
                type="button"
                data-tootik={t('Cancel')}
                className="btn btn-danger rounded-circle shadow-lg animate__animated animate__faster animate__fadeInUp"
                style={{ width: 48, height: 48 }}
                onClick={cancel}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <button
                type="submit"
                data-tootik={t('Save changes')}
                className={`btn btn-success rounded-circle shadow-lg animate__animated animate__faster ${
                  originalValue !== value
                    ? 'animate__fadeInUp'
                    : 'animate__fadeOutDown'
                }`}
                style={{ width: 48, height: 48 }}
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
            </div>
          </div>
        </form>

        {showAlert && value !== originalValue && (
          <div
            className="position-fixed w-100"
            style={{
              bottom: 72,
              left: 0,
              zIndex: 99,
              font: '400 0.875rem/1.375rem "Open Sans", sans-serif',
            }}
          >
            <div
              className="alert text-light mb-0 animate__animated animate__faster animate__fadeIn"
              style={{ background: 'rgba(0,0,0,.25)' }}
            >
              <div className="text-center font-weight-bold animate__animated animate__faster animate__bounceIn">
                {t('Please Save or Cancel changes')}
              </div>
              <div className="form-row">
                <div className="col-5 text-right">
                  {originalValue ? (
                    originalValue
                  ) : (
                    <span className="badge badge-success">{t('NEW')}</span>
                  )}
                </div>
                <div className="col-2 text-center">&#x2192;</div>
                <div className="col-5 text-left">
                  {value ? (
                    value
                  ) : (
                    <span className="badge badge-secondary">{t('EMPTY')}</span>
                  )}
                </div>
              </div>
              <div className="text-center small">
                {t('Press ESC to cancel')}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  return (
    <span
      className={!value ? 'text-secondary' : ''}
      onClick={(e) => setEdit(true)}
    >
      {value || t('Click to edit')}
    </span>
  );
}

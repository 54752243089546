import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faQuestionCircle,
  faUser,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons';
//import { myProfile } from '../api/auth.api';
import {
  updateUser,
  sendMailVerifcation,
  verifyEmail,
  findUser,
} from '../api/user.api';
import { extractVerifyTokenFrom } from './User';

export default function UserProfile(props) {
  const { state, messages, setMessages } = props;
  const { t } = useTranslation();
  const location = useLocation();

  const [user, setUser] = React.useState({});
  const [name, setName] = React.useState();
  const [verified, setVerified] = React.useState();

  const [change, setChange] = React.useState();
  const [redirect, setRedirect] = React.useState();

  // verify email
  React.useEffect(() => {
    if (user.verified === null) {
      return;
    }
    const verifyToken = extractVerifyTokenFrom(location);
    if (!verifyToken) {
      return;
    }
    async function emailVerfication() {
      try {
        const { access_token } = state;
        await verifyEmail(access_token, verifyToken);
        setVerified(true);
      } catch (error) {
        // TODO: change to proper error message
        setMessages([{ type: 'warning', value: 'Failed to verify email' }]);
      }
    }
    emailVerfication();
  }, [location, state, user, setMessages]);

  React.useEffect(() => {
    const { name, verified } = user;
    setName(name);
    setVerified(verified);
  }, [user]);

  React.useEffect(() => {
    async function fetchUser() {
      try {
        const { access_token, email } = state;
        // FIXED NOTE: passport seems to have separate cache hence fetching from user directly
        //const user = await myProfile(access_token);
        const user = await findUser(access_token, email);
        setUser(user);
      } catch (error) {
        if (error instanceof Error) {
          setRedirect(true);
        }
      }
    }
    fetchUser();
  }, [state]);

  if (redirect) {
    const verifyToken = extractVerifyTokenFrom(location);
    return <Redirect to={`/login?${verifyToken}`} />;
  }

  const { role, email, name: currentName, created, updated } = user;

  async function changeName(e) {
    try {
      e.preventDefault();

      const { access_token } = state;
      await updateUser(access_token, { email, name });

      setUser({ ...user, name, updated: new Date().toISOString() });
      setChange();
    } catch (error) {
      // TODO: change to proper error message
      setMessages([
        ...messages,
        { type: 'warning', value: 'Failed to change name' },
      ]);
    }
  }

  async function sendVerificationEmail(e) {
    try {
      const { access_token } = state;
      await sendMailVerifcation(access_token);

      // TODO: notify verification email has been sent
      setMessages([
        ...messages,
        { type: 'info', value: 'An email is sent to you for verification' },
      ]);
    } catch (error) {
      // TOD: display proper error message
      setMessages([
        ...messages,
        { type: 'warning', value: 'Failed to send the verification email' },
      ]);
    }
  }

  return (
    <div className="container-lg py-5">
      <h1 className="py-3">{t('My Profile')}</h1>
      <div>
        <div className="h1 py-3">
          <FontAwesomeIcon
            icon={role === 'user' ? faUser : faUserShield}
            className={role === 'user' ? 'text-secondary' : 'text-primary'}
          />
        </div>
        <div className="form-group">
          <label>{t('Name')}</label>
          {change === 'name' ? (
            <form onSubmit={changeName}>
              <div className="input-group">
                <input
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                />
                <div className="input-group-append">
                  <button type="submit" className="btn btn-primary border">
                    {t('Save')}
                  </button>
                </div>
              </div>
              <div className="">
                <button
                  type="button"
                  className="btn btn-link btn-sm small"
                  onClick={(e) => setChange()}
                >
                  {t('Cancel')}
                </button>
              </div>
            </form>
          ) : (
            <div>
              <span className="mr-2">{currentName}</span>
              <button
                className="btn btn-link"
                onClick={(e) => setChange('name')}
              >
                {t('Change')}
              </button>
            </div>
          )}
        </div>
        <div className="form-group">
          <label>{t('Email')}</label>
          <div className="">
            <span className="mr-2">{email}</span>
            <span
              data-tootik={
                verified ? t('Verified') : t('Please verify your email')
              }
            >
              <FontAwesomeIcon
                icon={verified ? faCheckCircle : faQuestionCircle}
                className={verified ? 'text-success' : 'text-secondary'}
              />
            </span>
            {!verified && (
              <small className="form-text">
                <button
                  className="btn btn-light border btn-sm rounded-pill px-3 font-weight-bold"
                  onClick={sendVerificationEmail}
                >
                  {t('Please send me a verification email')}
                </button>
              </small>
            )}
          </div>
        </div>
        {/*<div className="form-group">
          <label>{t('Ref')}</label>
          <div className="">{ref}</div>
        </div>*/}
        <div className="form-group">
          <label>{t('Created / Last Updated')}</label>
          <div className="">{updated || created}</div>
        </div>
      </div>
    </div>
  );
}

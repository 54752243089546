import React from 'react';
import { useTranslation } from 'react-i18next';
import BreadCrumb from '../component/BreadCrumb';
import Creations from './Creations';

export default function Home(props) {
  const { t } = useTranslation();
  const { state, notify } = props;
  return (
    <div className="container-lg py-5">
      <BreadCrumb />

      <h3>{t('Home')}</h3>

      <Creations state={state} notify={notify} />
    </div>
  );
}

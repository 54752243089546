import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getLandingPage } from '../api/landing-page.api';
import LandingPageStyle from './LandingPageStyle';
import LandingPageBody from './LandingPageBody';

export default function LandingPage(props) {
  const { state, notify } = props;
  const { t } = useTranslation();
  const { slug } = useParams();

  const [landingPage, setLandingPage] = React.useState();

  React.useEffect(() => {
    async function fetchLandingPage() {
      try {
        const { access_token } = state;
        const landingPage = await getLandingPage(access_token, slug);
        setLandingPage(landingPage);
      } catch (error) {
        notify(t(`Failed to fetch landing page`), 'danger');
      }
    }
    fetchLandingPage();
    // eslint-disable-next-line
  }, [state, slug]);

  const { design } = landingPage || {};

  return (
    <div className="">
      <LandingPageStyle design={design} />
      <LandingPageBody data={landingPage} />
      <h1>{slug}</h1>
      <pre>
        <code>{JSON.stringify(landingPage, null, 2)}</code>
      </pre>
    </div>
  );
}

import React from 'react';
import LandingPageNavbar from './LandingPageNavbar';
import LandingPageMain from './LandingPageMain';
import LandingPageFooter from './LandingPageFooter';

export default function LandingPageBody(props) {
  const { data } = props;
  const [scrollY, setScrollY] = React.useState();

  React.useEffect(() => {
    const eventListener = (event) => setScrollY(window.scrollY);
    window.addEventListener('scroll', eventListener);
    return () => {
      window.removeEventListener('scroll', eventListener);
    };
  }, []);

  return (
    <React.Fragment>
      <LandingPageNavbar scrollY={scrollY} data={data} />
      <LandingPageMain data={data} />
      <LandingPageFooter data={data} />
    </React.Fragment>
  );
}

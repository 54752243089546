import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../home/Home';
import Secret from './Secret';
import Notification from './Notification';
import UserLogin from '../user/UserLogin';
import UserSignUp from '../user/UserSignUp';
import UserProfile from '../user/UserProfile';
//import LandingCreate from '../landing/LandingCreate';
import LandingChooseDesign from '../landing/LandingChooseDesign';
import LandingPage from '../landing/LandingPage';

export default function Main(props) {
  const { state, setState, messages, setMessages, notify } = props;
  return (
    <main role="main">
      <Switch>
        <Route exact path="/">
          <Home state={state} notify={notify} />
        </Route>
        <Route exact path="/143">
          <Secret />
        </Route>
        <Route exact path="/login">
          <UserLogin
            state={state}
            setState={setState}
            setMessages={setMessages}
          />
        </Route>
        <Route exact path="/signup">
          <UserSignUp />
        </Route>
        <Route exact path="/me">
          <UserProfile
            state={state}
            messages={messages}
            setMessages={setMessages}
          />
        </Route>
        <Route exact path="/create">
          <LandingChooseDesign state={state} notify={notify} />
        </Route>
        <Route exact path="/landing-page/:slug">
          <LandingPage state={state} notify={notify} />
        </Route>
      </Switch>
      <Notification messages={messages} setMessages={setMessages} />
    </main>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { signUp } from '../api/auth.api';

export default function UserSignUp(props) {
  const { t } = useTranslation();

  const [email, setEmail] = React.useState('nieylarm+app.bina.asia@gmail.com');
  const [password, setPassword] = React.useState('1234567');
  const [confirmPassword, setConfirmPassword] = React.useState('1234567');

  const [alert, setAlert] = React.useState({});

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      if (password !== confirmPassword) {
        throw Error(`Password mismatch`);
      }

      //const { ok, status, statusText } = await signUp({ email, password });
      await signUp({ email, password });

      setAlert({ type: 'success', message: 'Sign up success' });
    } catch (error) {
      if (error instanceof Error) {
        const { message } = error;
        setAlert({ type: 'warning', message });
      } else {
        setAlert({
          type: 'danger',
          message: 'Something went wrong',
        });
      }
    }
  }

  if (alert.type === 'success') {
    // TODO: to display notification telling email need to be verified
    return (
      <div className="d-flex align-content-center align-items-center justify-content-center flex-wrap w-100 v-100 vh-100">
        <div className="container-xs">
          <h1 className="text-center">{t('Sign Up')}</h1>

          {alert.message && (
            <div className={`alert alert-${alert.type} text-center small`}>
              {alert.message}
            </div>
          )}

          <div className="text-center">
            <Link to="/login">{t('Login')}</Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex align-content-center align-items-center justify-content-center flex-wrap w-100 v-100 vh-100">
      <div className="container-sm">
        <h1 className="text-center">{t('Sign Up')}</h1>

        {alert.message && (
          <div className={`alert alert-${alert.type} text-center small`}>
            {alert.message}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">{t('Email')}</label>
            <input
              className="form-control"
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">{t('Password')}</label>
            <input
              className="form-control"
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">{t('Confirm Password')}</label>
            <input
              className="form-control"
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="d-flex justify-content-around py-3">
            <button type="submit" className="btn btn-primary">
              {t('Sign Up')}
            </button>
            <button type="button" className="btn btn-secondary">
              {t('Cancel')}
            </button>
          </div>
          <div className="text-center small">
            <span className="mr-1">
              {t('I have signed up. Please take me to')}
            </span>
            <Link to="/login">{t('login')}</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

import React from 'react';

export default function LandingPageMobilePreview(props) {
  const { data = {} } = props;
  const { screenPreview } = data;

  const style = {
    background: `url(/img/iphone-x-minimal-no-buttons.svg) 50% 0 no-repeat, url(${screenPreview}) 50% 50% / 57.5% no-repeat`,
  };

  return (
    <div className="image-container aspect-ratio aspect-ratio-2x3">
      <div className="mx-auto" style={style} />
    </div>
  );
}

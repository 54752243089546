import marked from 'marked';

export function fromMd(v) {
  if (typeof v === 'string') {
    marked.setOptions({
      gfm: true,
    });
    return { __html: marked(v) };
  }
  return { __html: '' };
}

export function randInt(n = 10) {
  return Math.floor(Math.random() * n);
}

export function randColor() {
  return `#${[...Array(3).keys()]
    .map((e) => (randInt(238) + 17).toString(16))
    .join('')}`;
}

export function generateId(prefix = '_', l = 6) {
  return `${prefix}${Date.now()}${new Array(l)
    .fill(0)
    .map((e) => randInt())
    .join('')}`;
}

export function extractUniqueIdFromId(id = '', l = 6) {
  if (!id) {
    return '';
  }
  return id.slice(-l);
}

export function extractDateFromId(id = '', l = 6) {
  if (!id) {
    return '';
  }
  const dateStr = id.slice(0, -l);
  const date = new Date(parseInt(dateStr));
  return date;
}

export function isUrl(s) {
  if (typeof s === 'string' && s.length > 0) {
    return s.startsWith('http') && s.indexOf('://') > 0;
  }
  return false;
}

// eslint-disable-next-line
const REGEX_EMAIL = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export function isEmail(s) {
  return REGEX_EMAIL.test(s);
}

// extract search param value from URL
export function extractSearchParam(url) {
  if (!url) return {};
  const searchParams = url.split('&');
  if (searchParams.length <= 0) {
    return {};
  }
  const params = {};
  searchParams.forEach((searchParam, i) => {
    const [key, value] = searchParam.split('=');
    if (i === 0) {
      params[key.slice(1)] = value;
    } else {
      params[key] = value;
    }
  });
  return params;
}

export function toNumber(n, d = -1) {
  if (typeof n === 'number' && !isNaN(n)) return n;
  if (typeof n === 'string' && n.length > 0) {
    if (n.indexOf('.') >= 0) return toNumber(parseFloat(n), d);
    else return toNumber(parseInt(n), d);
  }
  if (Array.isArray(n) && n.length > 0) return toNumber(n[0], d);
  if (typeof n === 'object' && n !== null) {
    const keys = Object.keys(n);
    if (Array.isArray(keys) && keys.length > 0) return toNumber(n[keys[0]], d);
  }
  if (typeof d === 'number' && !isNaN(d)) return d;
  return -1;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faSignInAlt,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import Constant from '../Constant';

export default function Navbar(props) {
  const { state, setState, setMessages } = props;
  const { t } = useTranslation();

  function logout(e) {
    setState({});
    setMessages([]);
  }

  const { email } = state;

  return (
    <nav className="navbar fixed-top navbar-expand navbar-light bg-light">
      <Link className="navbar-brand" to="/">
        <span className="mr-3">{Constant.APP_LOGO}</span>
        {Constant.APP_NAME}
      </Link>

      <div className="collapse navbar-collapse">
        <ul className="navbar-nav mr-auto">
          {/*<li className="nav-item">
            <Link className="nav-link" to="/">
              {t('Home')}
            </Link>
          </li>*/}
          {email && (
            <React.Fragment>
              <li className="nav-item">
                <Link className="nav-link" to="/create">
                  {t('Create')}
                </Link>
              </li>
            </React.Fragment>
          )}
        </ul>
        <ul className="navbar-nav">
          {email ? (
            <React.Fragment>
              <li
                className="nav-item animate__animated animate__faster animate__zoomIn"
                data-tootik={t('My Profile')}
                data-tootik-conf="bottom"
              >
                <Link to="/me">
                  <button className="btn-unstyled nav-link text-secondary">
                    <FontAwesomeIcon icon={faUser} />
                  </button>
                </Link>
              </li>
              <li
                className="nav-item active animate__animated animate__faster animate__zoomIn"
                data-tootik={t('Logout')}
                data-tootik-conf="bottom"
              >
                <button
                  className="btn-unstyled nav-link text-secondary"
                  onClick={logout}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </button>
              </li>
            </React.Fragment>
          ) : (
            <li
              className="nav-item active animate__animated animate__faster animate__zoomIn"
              data-tootik={t('Login')}
              data-tootik-conf="bottom"
            >
              <Link to="/login">
                <button className="btn-unstyled nav-link text-secondary">
                  <FontAwesomeIcon icon={faSignInAlt} />
                </button>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
}

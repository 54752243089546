import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'animate.css';
import 'tootik/css/tootik.min.css';
import 'swiper/css/swiper.min.css';
import './style/style.css';
//import './style/thinker.css';
import './style/media.css';
import './style/override.css';
import Body from './component/Body';
//import Body from './leno/Body';

export default function App() {
  return <Body />;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { InputSwitch, Modal } from '../util';
import { findAllLandingPage, deleteLandingPage } from '../api/landing-page.api';

export default function Creations(props) {
  const { t } = useTranslation();
  const { state, notify } = props;

  const [landingPages, setLandingPages] = React.useState([]);
  const [selectedSlug, setSelectedSlug] = React.useState();
  const [reload, setReload] = React.useState(0);
  const [deleteMode, setDeleteMode] = React.useState(false);

  React.useEffect(() => {
    async function fetchLandingPages() {
      try {
        const { access_token } = state;
        const landingPages = await findAllLandingPage(access_token);
        landingPages.sort((a, b) => {
          const x = a.updated || a.created;
          const y = b.updated || b.created;
          if (x > y) return -1;
          if (x < y) return 1;
          return 0;
        });
        setLandingPages(landingPages);
      } catch (error) {
        notify(`Failed to fetch landing pages`, 'warning');
      }
    }
    fetchLandingPages();
    // eslint-disable-next-line
  }, [state, reload]);

  async function removeLandingPage() {
    try {
      const { access_token } = state;
      await deleteLandingPage(access_token, selectedSlug);
      setSelectedSlug();
      setDeleteMode(false);
      setReload(reload + 1);
    } catch (error) {
      notify(`Failed to remove landing page ${selectedSlug}`, 'warning');
    }
  }

  return (
    <div className="container-lg py-5">
      <Modal
        show={!!selectedSlug}
        title={t('Remove {{slug}}', { slug: selectedSlug })}
        onOK={removeLandingPage}
        okLabel={t('Yes')}
        cancelLabel={t('No')}
        onClose={(e) => setSelectedSlug()}
      >
        <div className="text-center">
          <h3>{t('Are you sure?')}</h3>
        </div>
      </Modal>
      <h3>{t('My Creations')}</h3>
      <div className="py-3 text-right">
        <InputSwitch
          label={t('Remove')}
          value={deleteMode}
          onChange={setDeleteMode}
        />
      </div>
      <div className="list-group">
        {landingPages.map(({ slug }) => (
          <React.Fragment key={slug}>
            {deleteMode ? (
              <button
                className="list-group-item list-group-item-action border-danger text-danger"
                onClick={(e) => setSelectedSlug(slug)}
              >
                {slug}
                <span className="float-right">
                  <FontAwesomeIcon icon={faTrashAlt} />
                </span>
              </button>
            ) : (
              <Link
                className="list-group-item list-group-item-action"
                to={`/landing-page/${slug}`}
              >
                {slug}
              </Link>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

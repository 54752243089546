import React from 'react';
import Navbar from './Navbar';
import Main from './Main';
import Footer from './Footer';
import Storage from './Storage';

const STATE = 'state';

export default function Body(props) {
  const [state, setState] = React.useState(Storage.get(STATE, 'object') || {});
  const [messages, setMessages] = React.useState([
    //{ type: 'success', value: 'Success message' },
    //{ type: 'info', value: 'Info message' },
    //{ type: 'warning', value: 'Warning message' },
    //{ type: 'danger', value: 'Danger message' },
  ]);

  React.useEffect(() => {
    Storage.set(STATE, state);
  }, [state]);

  function notify(value, type) {
    setMessages([...messages, { value, type }]);
  }

  return (
    <React.Fragment>
      <Navbar state={state} setState={setState} setMessages={setMessages} />
      <Main
        state={state}
        setState={setState}
        messages={messages}
        setMessages={setMessages}
        notify={notify}
      />
      <Footer />
    </React.Fragment>
  );
}

import React from 'react';

export default function LandingPageDownloadLink(props) {
  const { url, ios = false } = props;
  if (!url) {
    return null;
  }
  return (
    <a
      href={url}
      className="btn-solid-lg page-scroll text-uppercase"
      target="_blank"
      rel="noreferrer noopener"
    >
      <i className={`fab ${ios ? 'fa-apple' : 'fa-google-play'}`}></i>
      {ios ? 'App Store' : 'Play Store'}
    </a>
  );
}

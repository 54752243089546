import React from 'react';
import { useTranslation } from 'react-i18next';
import '../style/Notification.css';

export default function Notification(props) {
  const { t } = useTranslation();
  const { messages, setMessages } = props;

  function removeMessage(i) {
    setMessages([...messages.slice(0, i), ...messages.slice(i + 1)]);
  }

  return (
    <div className="Notification">
      {messages.map(({ type = 'light', value }, i) => (
        <div
          key={i}
          className={`alert alert-${type} animate__animated animate__faster animate__fadeInLeft`}
        >
          <span className="mr-3">{value}</span>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label={t('Close')}
            onClick={(e) => removeMessage(i)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ))}
    </div>
  );
}

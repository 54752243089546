const API_URL = process.env.REACT_APP_API_URL;

export async function login({ email, password }) {
  const url = `${API_URL}/auth/login`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    // CAVEAT: must use username due to using passport
    body: JSON.stringify({ username: email, password }),
  });
  const responseJSON = await response.json();
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const { message } = responseJSON;
    throw new Error(message);
  }
  return responseJSON;
}

export async function signUp({ email, password }) {
  const url = `${API_URL}/user`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  const { ok, status, statusText } = response;
  return { ok, status, statusText };
}

export async function myProfile(token) {
  if (!token) {
    throw new Error('Not logged in');
  }
  const url = `${API_URL}/profile`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  const responseJSON = await response.json();
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const { message } = responseJSON;
    throw new Error(message);
  }
  return responseJSON;
}

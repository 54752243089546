const Constant = {
  APP_LOGO: "🦄",
  APP_NAME: "Bina Asia",
  APP_VERSION: "1.0",
  CREATED_DATE: "2020-06-19",
  OWNER_NAME: "Thinker Tech",
  OWNER_LOGO: "🦄",
  OWNER_WEBSITE: "https://www.thinkertech.io",
  OWNER_COUNTRY: "Malaysia",
};

export default Constant;

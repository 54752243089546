import React from 'react';

export function Modal(props) {
  const {
    title = '',
    onOK,
    onClose,
    okLabel = 'OK',
    cancelLabel = 'Cancel',
    children,
    show = false,
    footerChildren = null,
  } = props;
  return (
    <div
      className={`modal d-block animate__animated animate__faster ${
        show ? 'animate__fadeInDown' : 'animate__fadeOutUp'
      }`}
      data-backdrop="static"
      tabIndex="-1"
      role="dialog"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-scrollable shadow-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer text-center">
            <div>{footerChildren}</div>
            {onOK && (
              <button
                type="button"
                className="btn btn-primary shadow-sm px-4 rounded-pill"
                data-dismiss="modal"
                onClick={onOK}
              >
                {okLabel}
              </button>
            )}
            <button
              type="button"
              className="btn btn-secondary shadow-sm px-4 rounded-pill"
              data-dismiss="modal"
              onClick={onClose}
            >
              {cancelLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

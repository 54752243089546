import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { login } from '../api/auth.api';
import { extractVerifyTokenFrom } from './User';

export default function UserLogin(props) {
  const { state, setState, setMessages } = props;
  const { t } = useTranslation();
  const location = useLocation();

  const [email, setEmail] = React.useState('nieylarm+app.bina.asia@gmail.com');
  const [password, setPassword] = React.useState('1234567');

  const [alert, setAlert] = React.useState({});
  const [redirect, setRedirect] = React.useState();

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      const { access_token, role } = await login({ email, password });

      setState({ ...state, email, access_token, role });
      setMessages([]);
      setRedirect(true);
    } catch (error) {
      if (error instanceof Error) {
        const { message } = error;
        setAlert({
          type: 'warning',
          message: message || `Wrong Email or Password`,
        });
      } else {
        setAlert({
          type: 'danger',
          message: 'Something went wrong',
        });
      }
    }
  }

  if (redirect) {
    const verifyToken = extractVerifyTokenFrom(location);
    return <Redirect to={`/me?${verifyToken}`} />;
  }

  return (
    <div className="d-flex align-content-center align-items-center justify-content-center flex-wrap w-100 v-100 vh-100">
      <div className="container-sm">
        <h1 className="text-center">{t('Login')}</h1>

        {alert.message && (
          <div className={`alert alert-${alert.type} text-center small`}>
            {alert.message}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">{t('Email')}</label>
            <input
              className="form-control"
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">{t('Password')}</label>
            <input
              className="form-control"
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-around py-3">
            <button type="submit" className="btn btn-primary">
              {t('Login')}
            </button>
            <button type="button" className="btn btn-secondary">
              {t('Cancel')}
            </button>
          </div>
          <div className="text-center small">
            <span className="mr-1">{t('I want to')}</span>
            <Link to="/signup">{t('sign up')}</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

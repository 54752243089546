import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources: {
    en: {translation: require('./locale/en.json')},
    my: {translation: require('./locale/my.json')},
  },
  lng: 'en',
  fallbackLng: 'en',debug: false,
  ns: ['translation'],
  defaultNS: 'translation',
  keySeparator: ':',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true
  },
})

export default i18n

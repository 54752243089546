import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function BreadCrumbContent({ projectName, templateName, content }) {
  if (!content) {
    return null;
  }
  const { id, slug, value = {} } = content;
  const { name, title } = value;
  return (
    <li className="breadcrumb-item active" aria-current="page">
      {name || title || slug || id}
    </li>
  );
}

// context: displaying template in breadcrumb; the link has 2 contexts
// 1. Viewing from template context; link to template editor
// 2. Viewing from content context; link to list of contexts
function BreadCrumbTemplate({
  projectName,
  templateName,
  content,
  isContents,
}) {
  const { t } = useTranslation();
  if (!templateName) {
    return null;
  }
  if (!isContents && !content) {
    return (
      <li className="breadcrumb-item">
        <Link to={`/p/${projectName}/t/${templateName}`}>
          <span className="mr-1">{t('Edit')}</span>
          {templateName}
        </Link>
      </li>
    );
  }
  return (
    <li className="breadcrumb-item">
      <Link to={`/p/${projectName}/t/${templateName}/l`}>
        <span className="mr-1">{t('Contents of')}</span>
        {templateName}
      </Link>
    </li>
  );
}

export default function BreadCrumb(props) {
  const { t } = useTranslation();
  const {
    projectName,
    templateName,
    content,
    isNewProject = false,
    isNewTemplate = false,
    isContents = false,
    isNewContent = false,
  } = props;

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">{t('Home')}</Link>
        </li>

        {projectName && (
          <li className="breadcrumb-item">
            <Link to={`/p/${projectName}`}>{projectName}</Link>
          </li>
        )}

        {isNewProject && (
          <li className="breadcrumb-item active" aria-current="page">
            <span className="text-secondary font-italic">
              {t('New project')}
            </span>
          </li>
        )}

        <BreadCrumbTemplate
          projectName={projectName}
          templateName={templateName}
          content={content}
          isContents={isContents}
        />

        {isNewTemplate && (
          <li className="breadcrumb-item active" aria-current="page">
            <span className="text-secondary font-italic">
              {t('New template')}
            </span>
          </li>
        )}

        <BreadCrumbContent
          projectName={projectName}
          templateName={templateName}
          content={content}
        />

        {isNewContent && (
          <li className="breadcrumb-item active" aria-current="page">
            <span className="text-secondary font-italic">
              {t('New content')}
            </span>
          </li>
        )}
      </ol>
    </nav>
  );
}

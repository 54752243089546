import React from 'react';
import LandingPageDownloadLink from './LandingPageDownloadLink';
import LandingPageMobilePreview from './LandingPageMobilePreview';
import CTE from '../component/CTE';

export default function LandingPageHeader(props) {
  const { data = {} } = props;

  // cta is call-to-action
  //const { title = '', description = '', cta = '', download = {} } = data;
  const { download = {} } = data;
  const { ios, android } = download;

  const [title, setTitle] = React.useState();
  const [description, setDescription] = React.useState();

  return (
    <header id="header" className="header">
      <div className="header-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-container">
                <h1>
                  <CTE value={title} onChange={setTitle} />
                </h1>
                {/*<h1>{title}</h1>*/}
                <p className="p-large">
                  <CTE value={description} onChange={setDescription} />
                </p>
                {/*<p className="p-large">{`${description} ${cta}`}</p>*/}
                <LandingPageDownloadLink url={ios} ios />
                <LandingPageDownloadLink url={android} android />
              </div>
            </div>
            <div className="col-lg-6">
              <LandingPageMobilePreview data={data} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

const API_URL = process.env.REACT_APP_API_URL;

// access_token, {email,...rest} email is required
export async function updateUser(token, data) {
  if (!token) {
    throw new Error('Not logged in');
  }
  const url = `${API_URL}/user`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  const { ok, status, statusText } = response;
  return { ok, status, statusText };
}

export async function sendMailVerifcation(token) {
  if (!token) {
    throw new Error('Not logged in');
  }
  const url = `${API_URL}/user/send`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  const { ok, status, statusText } = response;
  return { ok, status, statusText };
}

export async function verifyEmail(token, verifyToken) {
  if (!token) {
    throw new Error('Not logged in');
  }
  const url = `${API_URL}/user/verify?token=${verifyToken}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  const { ok, status, statusText } = response;
  return { ok, status, statusText };
}

export async function findUser(token, email) {
  if (!token) {
    throw new Error('Not logged in');
  }
  const url = `${API_URL}/user/${email}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  const responseJSON = await response.json();
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const { message } = responseJSON;
    throw new Error(message);
  }
  return responseJSON;
}

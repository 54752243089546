const API_URL = process.env.REACT_APP_API_URL;

// access_token, creator email, design name
export async function createLandingPage(token, email, design) {
  if (!token) {
    throw new Error('Not logged in');
  }
  const url = `${API_URL}/landing-page`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ email, design }),
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  return await response.json();
}

export async function updateLandingPage(token, data) {
  if (!token) {
    throw new Error('Not logged in');
  }
  const url = `${API_URL}/landing-page`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  const { ok, state, statusText } = await response;
  return { ok, state, statusText };
}

export async function deleteLandingPage(token, slug) {
  if (!token) {
    throw new Error('Not logged in');
  }
  const url = `${API_URL}/landing-page/${slug}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  const { ok, state, statusText } = await response;
  return { ok, state, statusText };
}

export async function getLandingPage(token, slug) {
  if (!token) {
    throw new Error('Not logged in');
  }
  const url = `${API_URL}/landing-page/${slug}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  return await response.json();
}

export async function findAllLandingPage(token) {
  if (!token) {
    throw new Error('Not logged in');
  }
  const url = `${API_URL}/landing-page`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  return await response.json();
}
